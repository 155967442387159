<template>
  <v-container fluid class="px-2 py-2" style="height: 80vh">
    <v-row>
      <v-col md="12">
        <v-card class="bg-transparent">
          <v-row>
            <v-col lg="6">
              <h2 class="text-h2 text-typo font-weight-bolder mb-0">
                公告訊息
              </h2>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="z-index-2">
        <v-row style="height: 40vh">
          <v-col md="12">
            <bulletin
              :title="system.title"
              :type="system.type"
              :infos="bulletins"
            ></bulletin>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-card class="card-shadow border-radius-xl card-container">
              <div class="pt-2">
                <p class="font-weight-bold text-h6 text-typo mx-4 mb-0 py-0">
                  1.本月新增建物{{ building_this_month }}案、土地{{
                    land_this_month
                  }}案。
                </p>
                <p class="font-weight-bold text-h6 text-typo mx-4 mb-0">
                  2.累積系統現有建物{{ buildings_all }}案、土地{{ lands_all }}案
                </p>
              </div>
              <v-row>
                <v-col lg="6" class="z-index-2 pb-0">
                  <v-card class="card-shadow border-radius-xl mx-4">
                    <h6 class="mt-2 mb-2 text-h6 text-typo font-weight-bold">
                      新增案件數量統計
                    </h6>
                    <div
                      class="
                        bg-gradient-default
                        border-radius-lg
                        py-3
                        pe-1
                        mb-6
                      "
                    >
                      <div class="chart-area">
                        <canvas :id="salesChartID"></canvas>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col lg="6" class="z-index-2 pb-0">
                  <v-card class="card-shadow border-radius-xl px-4">
                    <h6 class="mt-2 mb-2 text-h6 text-typo font-weight-bold">
                      累積數量統計
                    </h6>
                    <div
                      class="
                        bg-gradient-default
                        border-radius-lg
                        py-3
                        pe-1
                        mb-6
                      "
                    >
                      <div class="chart-area">
                        <canvas :id="ordersChartID"></canvas>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>1.本月新增建物XX案、土地XX案。</v-row>
        <v-row>2.累積系統現有建物XX案、土地XX案。</v-row> -->

        <!-- <v-row>
          <v-col lg="6" class="z-index-2 align-end">
            <v-card class="card-shadow border-radius-xl px-2 py-2">
              <h6 class="ms-2 mt-2 mb-2 text-h6 text-typo font-weight-bold">
                新增案件數量統計
              </h6>
              <div class="bg-gradient-default border-radius-lg py-3 pe-1 mb-6">
                <div class="chart-area">
                  <canvas :id="salesChartID"></canvas>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col lg="6" class="z-index-2">
            <v-card class="card-shadow border-radius-xl px-2 py-2">
              <h6 class="ms-2 mt-2 mb-2 text-h6 text-typo font-weight-bold">
                累積數量統計
              </h6>
              <div class="bg-gradient-default border-radius-lg py-3 pe-1 mb-6">
                <div class="chart-area">
                  <canvas :id="ordersChartID"></canvas>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row> -->
      </v-col>

      <v-col>
        <bulletin
          :title="company.title"
          :type="company.type"
          :infos="bulletins"
        ></bulletin>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
// import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js/auto";
import Bulletin from "./Widgets/Bulletin.vue";

export default {
  name: "CRMDashboard",
  components: {
    Bulletin,
  },
  mixins: [HttpCommonMixin],
  data: function () {
    return {
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bulletins: [],
      bulletins_count: 0,
      system: {
        type: 0,
        title: "系統公告",
      },
      company: {
        type: 1,
        title: "公司公告",
      },
      buildings_all: 0,
      lands_all: 0,
      building: [],
      lands: [],
    };
  },
  mounted() {
    // ordersChart.createChart(this.ordersChartID);
    this.getBulletins();
  },
  computed: {
    building_this_month() {
      if (this.building && this.building.length > 0) {
        return this.building[this.building.length - 1];
      }
      return 0;
    },
    land_this_month() {
      if (this.lands && this.lands.length > 0) {
        return this.lands[this.lands.length - 1];
      }
      return 0;
    },
  },
  methods: {
    getBulletins() {
      console.log("get bulletins");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/available`;
      let params = {
        page: this.page,
        size: this.itemsPerPage,
      };

      this.doHttpGet(url, params, this.setResult);
    },
    setResult(result) {
      this.bulletins = result;
      this.bulletins_count = result.length;
      this.getSummary();
    },
    getSummary() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/bulletins/summary`;
      this.doHttpGet(url, {}, this.setSummary);
    },
    setSummary(result) {
      console.log("set summary", result);
      this.buildings_all = result.BA;
      this.lands_all = result.LA;
      this.building = result.B;
      this.lands = result.L;
      this.createBar(result.months, this.lands, this.building);

      let sum_b = [];
      let sum_l = [];
      let sum = 0;
      for (let i = 0; i < result.B.length; i++) {
        sum += result.B[i];
        sum_b.push(sum);
      }
      sum = 0;
      for (let i = 0; i < result.L.length; i++) {
        sum += result.L[i];
        sum_l.push(sum);
      }
      // this.createBar(result.months, result.B, result.L);
      this.createChart(this.ordersChartID, result.months, sum_l, sum_b);
    },
    createBar(labels, lands, buildings) {
      new Chart(document.getElementById(this.salesChartID).getContext("2d"), {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "土地",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#fff",
              data: lands,
              maxBarThickness: 6,
            },
            {
              label: "建物",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "#17ad37",
              data: buildings,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 500,

                beginAtZero: true,
                padding: 11,
                font: {
                  size: 14,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
                precision: 0,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    },
    createChart(chartId, labels, lands, buildings) {
      const ctx = document.getElementById(chartId).getContext("2d");

      var gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, "rgba(23,173,55,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(23,173,55,0)"); //purple colors

      var gradientStroke2 = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)"); //purple colors

      new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "土地",
              tension: 0.4,
              pointRadius: 0,
              borderColor: "#3A416F",
              borderWidth: 3,
              backgroundColor: gradientStroke2,
              fill: true,
              data: lands,
              maxBarThickness: 6,
            },
            {
              label: "建物",
              tension: 0.4,
              pointRadius: 0,
              borderColor: "#17ad37",
              borderWidth: 3,
              backgroundColor: gradientStroke1,
              fill: true,
              data: buildings,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#b2b9bf",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#b2b9bf",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>
