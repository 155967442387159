<template>
  <v-card
    class="card-shadow border-radius-xl card-container"
    :height="type == 1 ? '70vh' : '38vh'"
  >
    <v-dialog v-model="detail.dialog" width="60%">
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          {{ detail.title }}
        </v-card-title>
        <v-card-text class="font-weight-bold text-h5">
          {{ detail.content }}
        </v-card-text>
        <v-card-text
          class="font-weight-bold text-h6"
          v-if="detail.link != null && detail.link.length > 0"
        >
          <a
            :href="detail.link"
            class="text-typo text-body-2 font-weight-bold"
            target="_blank"
            >連結</a
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDetail()">
            關閉
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="px-4 pt-4 pb-0">
      <p class="font-weight-bold text-h4 text-typo mb-0">{{ title }}</p>
    </div>
    <!-- <v-card-text class="px-0 py-2"> -->
    <v-list :class="type == 1 ? 'company-list-container' : 'list-container'">
      <v-list-item
        v-for="(bulletin, index) in bulletin_info"
        :key="index"
        class="px-4"
        :class="{ 'border-bottom': index != infos.length - 1 }"
      >
        <v-list-item-content @click="showDetail(bulletin)">
          <v-list-item-title
            v-text="bulletin.title"
            class="font-weight-bold text-h4 text-container bulletin-title"
          ></v-list-item-title>
          <v-list-item-subtitle
            class="text-wrap font-weight-bold text-h5 text-container"
            >{{ bulletin.content }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- </v-card-text> -->
  </v-card>
</template>
<script>
import _ from "lodash";
import CommonUtility from "@/util/CommonUtility.js";
export default {
  props: {
    title: {
      type: String,
      default: "系統公告",
    },
    type: {
      type: Number,
      default: 0,
    },
    infos: {
      type: Array,
      default: () => [],
    },
  },
  name: "bullet",
  data() {
    return {
      debug: true,
      bulletin_info: [],
      speed: { type: "pps", number: 10 },
      countries: [
        {
          avatar: require("@/assets/img/icons/flags/US.png"),
          country: "United States",
          sales: "2.500",
          value: "$230,900",
          bounce: "29.9%",
        },
        {
          avatar: require("@/assets/img/icons/flags/DE.png"),
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
        },
        {
          avatar: require("@/assets/img/icons/flags/GB.png"),
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
        },
        {
          avatar: require("@/assets/img/icons/flags/BR.png"),
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
        },
      ],
      detail: {
        dialog: false,
        title: null,
        context: null,
        link: null,
      },
    };
  },
  mounted() {
    this.parseBulletinInfo(this.infos);
  },
  watch: {
    infos: function (newVal, oldVal) {
      // this.print_log("watch", newVal, oldVal);
      this.parseBulletinInfo(newVal);
    },
  },
  methods: {
    preCheckData(val) {
      if (val == null || val == undefined || val == "") {
        return " ";
      }
      return val;
    },
    parseBulletinInfo(val) {
      let infos = _.cloneDeep(val);
      // if the type of item in infos is equal to type, then push it to bulletin_info
      if (this.type == 0) {
        this.bulletin_info = infos.filter((item) => item.type == this.type);
      } else {
        // 找出item.type == this.type 或 item.type == 3 的item 遇到item.type == 2 將其type改為1
        this.bulletin_info = infos
          .map((item) => {
            if (item.type == 2) {
              let data = JSON.parse(item.content);
              let money = CommonUtility.formatNumber(data.month_sales * 10000);
              // if data.month_buildings is null set to ""
              data.month_buildings = this.preCheckData(data.month_buildings);
              data.month_lands = this.preCheckData(data.month_lands);
              money = this.preCheckData(money);
              let msg = `建物${data.month_buildings}件、土地${data.month_lands}件，累積業績${money}元。`;
              item.content = msg;
            } else if (item.type == 3) {
              let data = JSON.parse(item.content);
              let money = CommonUtility.formatNumber(data.year_sales * 10000);
              data.year_buildings = this.preCheckData(data.year_buildings);
              data.year_lands = this.preCheckData(data.year_lands);
              let msg = `建物${data.year_buildings}件、土地${data.year_lands}件`;
              item.content = msg;
            }
            return item;
          })
          .filter(
            (item) => item.type == this.type || item.type == 3 || item.type == 2
          );
      }
    },
    print_log(...args) {
      if (this.debug) {
        console.log("[", this.title, "]", ...args);
      }
    },
    getLink(item) {
      if (item.link) {
        return "連結";
      }
      return "";
    },
    showDetail(item) {
      console.log("showDetail", item);
      this.detail.title = item.title;
      this.detail.content = item.content;
      this.detail.link = item.link;
      this.detail.dialog = true;
    },
    closeDetail() {
      this.detail = {
        dialog: false,
        title: null,
        context: null,
        link: null,
      };
    },
  },
};
</script>

<style scoped>
.card-container {
  overflow: hidden;
}

.list-container {
  max-height: calc(40vh - 80px); /* 30vh 减去一些 padding/margin 的高度 */
  overflow-y: auto;
}

.company-list-container {
  max-height: calc(80vh - 160px); /* 30vh 减去一些 padding/margin 的高度 */
  overflow-y: auto;
}

.text-container {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示的最大行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bulletin-title {
  font-weight: bold;

  color: #f5aa00 !important;
}
</style>
